<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'"> {{ $t("message.payments_expense") }}</p>
                    <el-button @click="closeModal()" type="warning" plain>{{$t("message.close")}}</el-button>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 " v-loading="loadingData">
               <div class="elemet-content">
                  <div class="timeline-items__right rounded-sm w-100  p-3" :class="mode ? 'table__myday' : 'table__mynight'">
                    <div class="d-flex" style="justify-content: flex-end;"><el-button size="mini"  @click="showCreate(child.business_trip_id)">{{$t("message.add_new")}}</el-button></div>
                    <div class="card-table-header table-crm-smart">
                      <table
                          class="table-my-code table-bordered"
                          :class="mode ? 'table__myday' : 'table__mynight'"
                          v-loading="loadingData"
                      >
                          <thead>
                              <tr>
                                  <th class="w50p" v-if="columns.id.show">
                                      {{ columns.id.title }}
                                  </th>
      
                                  <th v-if="columns.type_expense.show">
                                      {{ columns.type_expense.title }}
                                  </th>
                                  <th v-if="columns.money_amount.show">
                                    {{ columns.money_amount.title }}
                                  </th>
                                  <th>
                                    {{ $t('message.whose_side')}}
                                  </th>
                                  <th v-if="columns.branch.show">
                                    {{ columns.branch.title }}
                                  </th>
                                  <th v-if="columns.datas.show">
                                    {{ columns.datas.title }}
                                  </th>
                                  <th v-if="columns.account.show">
                                    {{ columns.account.title }}
                                  </th>
                                  <th v-if="columns.comment.show">
                                    {{ columns.comment.title }}
                                  </th>
                                  <th v-if="columns.created_at.show">
                                      {{ columns.created_at.title }}
                                  </th>
                              </tr>
                          </thead>
                          <transition-group name="flip-list" tag="tbody">
                              <tr
                                  v-for="payment_type in expenses"
                                  :key="payment_type.id"
                                  class="cursor-pointer">
                                  <td v-if="columns.id.show">{{ payment_type.id }}</td>
                                  <td v-if="columns.type_expense.show">
                                      {{ payment_type.type_expense.name }}
                                  </td>
                                  <td v-if="columns.money_amount.show">
                                    {{ payment_type.money_amount }} 
                                    {{ payment_type.currency.symbol }}
                                  </td>
                                  <td >
                                    <div v-if="payment_type.whose_side == 'by_employe'">
                                      <el-tag type="info"   
                                              effect="dark" 
                                              style="margin-right:5px; margin-bottom: 5px;" 
                                              v-for="staff in payment_type.staffs" :key="staff.id">
                                              {{staff.name}} {{staff.last_name}}
                                      </el-tag>
                                    </div>
                                    <div v-else>
                                      <el-tag type="info"   
                                              effect="dark" 
                                              style="margin-right:5px; margin-bottom: 5px;">Kompaniya tomonidan</el-tag>
                                    </div>
                                  </td>
                                  <td v-if="columns.branch.show">
                                    {{ payment_type.branch?payment_type.branch.name:'' }}
                                  </td>
                                  <td v-if="columns.datas.show">
                                    {{ payment_type.date }}
                                  </td>
                                  <td v-if="columns.account.show">
                                    {{ payment_type.account?payment_type.account.name:'' }}
                                  </td>
                                  <td v-if="columns.comment.show">
                                    {{ payment_type.comment }}
                                  </td>
                                  <td v-if="columns.created_at.show">
                                      {{ payment_type.created_at }}
                                  </td>
                              </tr>
                          </transition-group>
                      </table>
                  </div>
                  </div>
               </div>
            </div>
        </div>
        <el-drawer
          title="I'm outer Drawer"
          :visible.sync="showCreateExpenseModal"
          size="50%"
          :with-header="false"
          :append-to-body="true"
          ref="show-expense"
          @opened="drawerOpened('show-expense')"
        >
          <div>
            <create-expense
              @closeCreateExpenseModal="closeCreateExpenseModal"
              ref="show-expense"
              :child="child"
              :business_trip_id="child.business_trip_ids"
              ></create-expense>
              <!-- :child="child" -->
          </div>
        </el-drawer>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
import createExpense from "./create-expense";

export default {
    name: "show-expense",
    mixins: [drawer],
    components: {createExpense},
    props: {
      child:{
        default: null,
      },
    },
    data() {
        return {
          showCreateExpenseModal: false,
          loadingData: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        expenses: "businessTrip/expenses",
        columns: "paymentsExpense/columns",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
        getExpenses: "businessTrip/getExpenses",
    }),

    opened(child) {
      this.listChanged()
    },
    listChanged(){
      if (!this.loadingData) {
        this.loadingData = true;
        this.getExpenses(this.child)
          .then((res) => {
            this.loadingData = false;
           
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal(){
      this.$emit("closeExpenseModal", false);
    },
    closeCreateExpenseModal(val) {
      this.showCreateExpenseModal = val;
    },
    showCreate(val) {
      console.log(this.child)
        this.showCreateExpenseModal = true;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
  }
};
</script>
<style lang="scss" >
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    //background-color: #fff;
    background-clip: border-box;
  //  border: 1px solid rgba(34,41,47,.1254901961);
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
