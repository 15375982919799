<template >
    <div>
        <div class="">
            <div class="app-modal__in">
                <div class="app-modal__header d-flex f-between p-5 m-title-modal" >
                    <p class="large--title m-0">
                        {{
                            $t("message.new_m", {
                                m: $t("message.organization"),
                            })
                        }}
                    </p>
                   
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="rounded-sm w-100 p-4">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form
                                ref="form"
                                status-icon
                                :model="form"
                                :rules="rules"
                            >
                                <el-form-item
                                    :label="$t('message.name')"
                                    prop="name"
                                    class="label_mini"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.name"
                                        v-model="form.name"
                                        :placeholder="$t('message.name')"
                                    ></crm-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!-- end col -->
                    </el-row>
                     <span >
                       <el-button type="primary" @click="submitRegion(true)"> {{$t("message.save")}} </el-button>
                      <el-button type="warning" @click="closeModal()"> {{$t("message.close")}} </el-button>

                    </span>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dialog from "@/utils/mixins/dialog";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
     mixins: [form, dialog],
    name: "RegionController",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "organization/rules",
            model: "organization/model",
            columns: "organization/columns",
        }),
    },
    methods: {
        ...mapActions({
            save: "organization/store",
            updateInventory:"organization/inventory"
        }),
        submitRegion(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                             if (res.status == 201) {
                                this.form ={};
                                this.closeModal();
                                this.updateInventory();
                                this.$parent.$parent.form.organization_id =res.data.result.data.organization.id;
                              }
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
         closeModal() {
              this.$emit("closeRegionModal", false);
            },
    },
};
</script>
